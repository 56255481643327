<template>
  <!-- div background form -->
  <div class="register-page d-flex justify-center">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="register-form"
    >
      <v-card class="register-card">
        <v-card-title class="register-card-title justify-center">
          <span v-text="$t('auth.signUp.signUp')" />
        </v-card-title>
        <!-- Content Card Form -->
        <v-card-text class="px-0">
          <!-- <v-text-field
            v-model="dataForm.firstname"
            :rules="nameRules"
            :label="$t('auth.signUp.firstName')"
            prepend-icon="mdi-account-outline"
          />
          <v-text-field
            v-model="dataForm.lastname"
            :rules="nameRules"
            :label="$t('auth.signUp.lastName')"
            prepend-icon="mdi-account-outline"
          /> -->
          <v-text-field
            v-model="dataForm.email"
            :rules="emailRules"
            :label="$t('auth.signUp.email')"
            prepend-icon="mdi-email-outline"
          />
          <v-text-field
            v-model="dataForm.phone"
            :rules="phoneRules"
            :label="$t('auth.signUp.phone')"
            prepend-icon="mdi-phone-outline"
          />
          <v-text-field
            v-model="dataForm.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules.required, passwordRules.min, passwordRules.max]"
            :type="show ? 'text' : 'password'"
            :label="$t('auth.signUp.password')"
            prepend-icon="mdi-lock-outline"
            @click:append="show = !show"
          />
          <v-text-field
            v-model="dataForm.verify"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules.required, confirmPassword]"
            :type="show ? 'text' : 'password'"
            :label="$t('auth.signUp.confirmPassword')"
            prepend-icon="mdi-lock-outline"
            @click:append="show = !show"
          />
        </v-card-text>
        <!-- v-card-actions -->
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                x-large
                rounded
                :disabled="!valid"
                color="success"
                width="100%"
                @click="register"
                v-text="$t('auth.signUp.signUp')"
              />
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <v-btn
                text
                @click="$router.push('login')"
                v-text="$t('auth.signUp.signIn')"
              />
            </v-col>
          </v-row>
        </v-card-actions>
        <!-- end v-card-actions -->
      </v-card>
    </v-form>
    <language />
  </div>
</template>

<script>
  import Language from '@/layouts/default/widgets/Language'
  import Repository from '../repository/RepositoryFactory'
  const RegisterRepository = Repository.get('register')
  export default {
    name: 'RegisterForm',
    components: {
      Language,
    },
    data: () => ({
      apiKey: '',
      dataForm: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        verify: '',
      },
      // error form email and password
      valid: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      phoneRules: [
        (v) => !!v || 'Phone is required',
        (v) => /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(v) || 'Phone must be valid',
      ],
      show: false,
      passwordRules: {
        required: (v) => !!v || 'Password is required',
        min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        max: (v) => (v && v.length <= 255) || 'Max 255 characters',
      },
      // end form email and password
    }),
    computed: {
      confirmPassword () {
        return () => this.dataForm.password === this.dataForm.verify || 'The password is not correct'
      },
    },
    async created () {
      const registerRepository = await RegisterRepository.getAPIKey()
      const dataResult = registerRepository.data
      // if apiKey.status = true then apiKey of data = apiKey.data
      if (dataResult.status) {
        this.apiKey = dataResult.data.apiKey
      }
    },
    methods: {
      async register () {
        const valueCheck = await this.$refs.form.validate()
        // if valueCheck = false then stop function register
        if (!valueCheck) {
          return
        }
        // Data validate ok
        const dataBody = {
          email: this.dataForm.email,
          phone: this.dataForm.phone,
          password: this.dataForm.password,
          passwordConfirm: this.dataForm.verify,
        }
        // call API register
        this.showProgressCircular()
        const registerRepository = await RegisterRepository.register(dataBody, this.apiKey)
        this.hideProgressCircular()
        // register = API return data
        const register = registerRepository.data
        // if register.status = false then error register.data.message
        if (!register.status) {
          this.$toast.error(register.data.message)
          return
        }
        // if register.status = true then success register.data.message
        // then push page VerifyRegisterForm
        this.$toast.success(register.data.checkEmail.message)
        this.$router.push({
          path: 'verify-register',
          query: {
            userId: register.data?.userId,
            tokenActive: register.data?.tokenActive,
          },
        })
      },
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Register',
    },
  }
</script>

<style lang="scss" scoped>
  // full background desktop 100vh
  $fullScreen: 100vh;
  $backgroundImage: url("https://colorlib.com/etc/lf/Login_v4/images/bg-01.jpg");
  .register-page {
    background-image: $backgroundImage;
    background-size: cover;
    padding: 15px;
    min-height: $fullScreen;
    .register-form {
      width: 500px;
      .register-card {
        padding: 55px;
        &-title {
          font-size: 39px;
          font-weight: bold;
        }
      }
    }
  }
  // media mobie
  @media only screen and (max-width: 600px) {
    .register-card {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
</style>
